<template>
  <BaseList
    :list-query="signa1"
    :filter="
      signum1 => showDeprecated || (!signum1.altschema && !signum1.deprecated)
    "
    :label="label || $t('components.signa1List.chooseSignum1')"
    :completions-query="completionsQuery"
    :name-map="nameMap"
    locale-section="pages.signa1"
    @select="signum1Selected"
  >
    <!-- Template for each element in the list -->
    <template
      v-slot:element="{ element: { signum1, shortSource, altschema, id } }"
    >
      <a
        href=""
        @click.prevent="
          clickSignum1({
            id,
            current: currentSignum1 == signum1,
            signum1,
            shortSource,
            altschema
          })
        "
      >
        <!-- eslint-disable-next-line -->
        {{ signum1 }} (<span v-html="shortSource" />)
      </a>
      <slot
        :element="{
          id,
          current: currentSignum1 == signum1,
          signum1,
          shortSource,
          altschema
        }"
        name="signum1"
      >
      </slot>
    </template>
    <template v-slot:list-extras>
      <slot name="list-extras" />
    </template>
  </BaseList>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "Signa1ExpandableList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  props: {
    label: {
      type: String,
      default: null
    },
    completionsQuery: {
      type: Object,
      default: null
    },
    preselectedSignum1: {
      type: String,
      default: ""
    },
    showDeprecated: { type: Boolean, default: false },
    nameMap: {
      type: Function,
      default: e => e
    }
  },
  data() {
    return {
      currentSignum1: this.preselectedSignum1,
      signa1: gql`
        query {
          list: signa1 {
            name: signum1
            id
            signum1
            source
            shortSource
            altschema
            deprecated
          }
        }
      `
    };
  },
  methods: {
    clickSignum1(signum1) {
      if (this.currentSignum1 == signum1.signum1) {
        this.currentSignum1 = "";
      } else {
        this.currentSignum1 = signum1.signum1;
      }

      this.$emit("click", {
        signum1,
        current: this.currentSignum1 == signum1.signum1
      });
    },
    signum1Selected(signum1) {
      this.$emit("select", signum1);
    }
  }
};
</script>
