var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseList',{attrs:{"list-query":_vm.signa1,"filter":signum1 => _vm.showDeprecated || (!signum1.altschema && !signum1.deprecated),"label":_vm.label || _vm.$t('components.signa1List.chooseSignum1'),"completions-query":_vm.completionsQuery,"name-map":_vm.nameMap,"locale-section":"pages.signa1"},on:{"select":_vm.signum1Selected},scopedSlots:_vm._u([{key:"element",fn:function({ element: { signum1, shortSource, altschema, id } }){return [_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.clickSignum1({
          id,
          current: _vm.currentSignum1 == signum1,
          signum1,
          shortSource,
          altschema
        })}}},[_vm._v(" "+_vm._s(signum1)+" ("),_c('span',{domProps:{"innerHTML":_vm._s(shortSource)}}),_vm._v(") ")]),_vm._t("signum1",null,{"element":{
        id,
        current: _vm.currentSignum1 == signum1,
        signum1,
        shortSource,
        altschema
      }})]}},{key:"list-extras",fn:function(){return [_vm._t("list-extras")]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }